import React from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import CreateAccount from './pages/Create Account/CreateAccount';
import ManageAccounts from './pages/Manage Account/ManageAccounts';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<CreateAccount/>}/>
      {/* <Route path='/manage-accounts' element={<ManageAccounts/>}/> */}
    </Routes>
    </>
  );
}

export default App;
