import React, { Component } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./CreateAccountStyle.css";

export default class CreateAccount extends Component {
  render() {
    return (
      <>
        <Navbar />
        <div className="mt-4 d-flex align-items-center justify-content-center">
           <div className="text-center">
              <h1>Website Moved Permanently</h1>
              <h2>This website has been permanently moved to a new location.</h2>
              <p>
                Visit new site at :&nbsp;  
                <a href="https://simbanks.finfactor.in/" target="_blank">
                  Here
                </a>
             </p>
          </div>
        </div>
      </>
    );
  }
}
